/* eslint-disable import/no-anonymous-default-export */
// initial state

export default {
  home: {
    isAuth: false,
    imageList: [],
    pid: null,
    pType: null,
    sid: null,
    sesCount: null,
    sentenceList: [],
    baseIKI: null,
    fastSentences: null,
    prevResult: []
  }
};
