// Action types

// Home actions
export const SET_STORE_IMAGES = 'SET_STORE_IMAGES';
export const SET_PARTICIPANT_ID = 'SET_PARTICIPANT_ID';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_SESSION_COUNT = 'SET_SESSION_COUNT';
export const SET_SENTECES = 'SET_SENTECES';
export const SET_RESULT_DATA = 'SET_RESULT_DATA';
export const SET_PREV_RESULT = 'SET_PREV_RESULT';
export const SET_IS_AUTH = 'SET_IS_AUTH';
export const RESET_STATE = 'RESET_STATE';
