// import logo from './logo.svg';
import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import UserStudy from './Components/UserStudy';
import AppRouter from './AppRouter'
import Login from '../src/Components/Login'
import Webgazer from './App_webgazer'
import EyeTrackingComponent from './EyeTracking';
import api from './apiSetup'

function App() {

    const testbackend = async () => {
        try {
            const response = await api.get('/');
            console.log(response.data)
            // setSentences(response.data);
        } catch (error) {
            console.error('Error fetching sentences:', error);
        }
    };

    useEffect(() => {
        testbackend()
    }, [])

    return (
        <div className="App">
            {/* <Webgazer/> */}
            {/* <EyeTrackingComponent /> */}
            {/* <Login /> */}
            <AppRouter />
        </div>
    );
}

export default App;
