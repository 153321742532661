import React from 'react';
import './ResultProgress.css'; // Import CSS file for styling

const ProgressBar = ({ current }) => {
    const max = 10;
    const percentage = (current / max) * 100;

    return (
        <div className="result-progress-container">
            <div className="label-container">
                <span className="progress-label">0</span>
                <div className="result-progress-bar">
                    <div className="progress-fill" style={{ width: `${percentage}%` }}>
                        {/* <span className="progress-value">{`${current}`}</span> */}
                    </div>
                </div>
                <span className="progress-label">10</span>
            </div>
        </div>
    );
}

export default ProgressBar;
