import React, { useState } from 'react';
import './styles.css'; // Import the CSS file

const ProgressDisplay = ({ current }) => {
    // const [current, setCurrent] = useState(5);

    return (
        <div className="progress-container">
            <span className="progress-text" style={{ color: '#333333' }}>
                {current}/20
            </span>
        </div>
    );
};

export default ProgressDisplay;
