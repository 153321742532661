import React, { useState, useEffect, useRef } from 'react';
import { S3Client, ListObjectsV2Command } from '@aws-sdk/client-s3';
import { setStoreImgs, setSessionId, setSessionCount, setSentences } from '../store/actions/homeActions'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TEST } from '../RouteConst';
import homeclass from './Home.module.css'
import { Paper } from '@mui/material';
import Button from '../UI/Button'
import Alert from '@mui/material/Alert';
import parse from 'html-react-parser';
import api from '../apiSetup'



const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pid = useSelector(state => state.home.pid);
    const pType = useSelector(state => state.home.pType);
    const sid = useSelector(state => state.home.sid);
    const sesCount = useSelector(state => state.home.sesCount);

    const [imageList, setImages] = useState([]);
    const [imgIndex, setImgIndex] = useState(1)
    const [isMobile, setIsMobile] = useState(false)
    // const [sesCount, setSesCount] = useState(null)


    const instructionsData = {
        infobox: "This brief test allows you to test how fast you can type on your device and compare your speed with others. Data is collected and used for scientific purposes only and strictly anonymized.",
        main: "Instructions",
        note: "Please read carefully before continuing.",
        instructions1: (pType && pType === 'composition') ? "The writing task will take no more than 15 minutes. You will be presented with 20 images, one by one, and your task is to describe or caption each image in one sentence.": "The writing task will take no more than 15 minutes. You will be presented with 10 images, one by one, and your task is to describe or caption each image in one sentence. Additionally, you will need to transcribe 10 sentences, one at a time.",
        // instructions2: 'For each sentence, <b> timing starts only after the first keystroke </b> and stops after the last one. Press the "Enter" key or click the "Next" button to type the next sentence.',
        instructions2: "Make sure each sentence has minimum 5 words. Think about the sentence you want to type before you start typing: This helps improve typing accuracy and flow.",
        instructions3: (pType && pType === 'composition') ? 'The typing task is divided into three phases: <li> <b>Phase 1:</b> Describe the first 5 images normally. </li> <li> <b>Phase 2:</b> You will then be instructed to <b>describe the next 10 images with the highest speed and accuracy possible</b>. The performance bar fills with green for each sentence typed quickly compared to your regular speed.</li><li><b>Phase 3:</b> Describe the final 5 images normally.</li> <b>Note: </b>For each sentence, <b> timing starts only after the first keystroke </b> and stops after the last one. Press the "Enter" key or click the "Next" button to type the next sentence.'
       : 'The typing task is divided into three phases: <li> <b>Phase 1:</b> Describe the first 5 images normally. </li> <li> <b>Phase 2:</b> You will then be instructed to <b>transcribe the next 10 sentences with the highest speed and accuracy possible </b>. The performance bar fills with green for each sentence typed quickly compared to your regular speed.</li><li><b>Phase 3:</b> Finally, you will be notified to describe the final 5 images normally.</li> <b>Note: </b> For each sentence, <b> timing starts only after the first keystroke </b> and stops after the last one. Press the "Enter" key or click the "Next" button to type the next sentence. ',
        instructions4: "If you notice any errors in your sentence, you can correct them.",
        instructions5: "You cannot navigate back to previous sentences using the browser’s navigation button. Doing so will invalidate your attempt, and you will need to redo the writing task.",
        instructions6: "After typing all 20 sentences, you will see details about your daily typing progress and performance.",
        // instructions8: "You will return to do the writing task again the next day.",
        // instructions6: 'Please, note, that slow network connection might momentarily disable the "Next" button until the next sentence is loaded.',
        start: "Start the test!"
    }

    // useEffect(() => {
    //     setSesCount(session_count)
    // }, [session_count])


    const fetchImages = async (sCount) => {
        try {
            // Configure S3 client
            const s3 = new S3Client({
                region: 'us-east-1', // e.g., 'us-east-1'
                credentials: {
                    accessKeyId: 'AKIAYS2NTILCWHJOMBLW',
                    secretAccessKey: 'qYdlaAgf9nRw83LuqqYgH7bC2/rTATZU9rbJGWz0'
                }
            });

            // Create command to list objects
            const command = new ListObjectsV2Command({
                Bucket: 'typingintervnetionimages',
                Prefix: `day_0${sCount + 1}`,
            });

            // Fetch the list of objects
            const data = await s3.send(command);
            const bucketName = 'typingintervnetionimages'
            // Generate URLs for all objects
            const imageUrls = data.Contents.map(item => `https://${bucketName}.s3.amazonaws.com/${item.Key}`);
            imageUrls.shift()
            let imgList = imageUrls
            if (pType === 'transcription') {
                imgList = imageUrls.slice(10);
            }
            setImages(imgList);
            dispatch(setStoreImgs(imgList))

        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    // const getSessionCount = async () => {
    //     try {
    //         // const response = 
    //         // await api.get(`/testBackend`);
    //         const response = await api.get(`/getSessionCount?pid=${pid}`);
    //         if (response.data.session_count === null) {
    //             // setDialog(true)
    //             setSesCount(0)
    //             dispatch(setSessionCount(0))
    //             return 0
    //         } else {
    //             setSesCount(response.data.session_count)
    //             dispatch(setSessionCount(response.data.session_count))
    //             return response.data.session_count
    //             // navigate(TEST);
    //         }
    //         // console.log(response)
    //         // return response.data.isValidCode
    //     } catch (error) {
    //         // setErrMsg("Invalid Continue code!")
    //         throw error
    //         // return null
    //     }
    // };
    const addSessionData = async () => {
        // console.log(isFirstSentence, imgIndex)
        const sessionData = {
            pid: pid,
            browserString: window.navigator.userAgent,
            screenWidth: window.screen.availWidth,
            screenHeight: window.screen.availHeight,
        }
        try {
            const response = await api.post('/addSessionData', sessionData);
            if (response.data.id) {
                console.log(response.data.id)
                dispatch(setSessionId(response.data.id))
                // const sCount = getSessionCount()
                return Promise.resolve();
            } else {
                // return null
                return Promise.reject("Response does not contain session count");
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    useEffect(() => {
        const isMobile = window.innerWidth < 768;
        if (isMobile) {
            setIsMobile(true)
        } else {
            console.log(sid, imageList)
            if (imageList.length === 0 && sid === null) {
                addSessionData().then(() => {
                    if (sesCount >= 0) {
                        fetchImages(sesCount);
                    }
                    // setTsId(testSectionId)
                }).catch((error) => {
                    // Handle error if needed
                    console.error('Error:', error);
                });
                if (pType === 'transcription') {
                    fetchSentences(sesCount);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSentences = async (sesCount) => {
        try {
            const response = await api.get(`/sentences?sesCount=${sesCount+1}`);
            console.log(response.data)
            dispatch(setSentences(response.data))
            // setSentences(response.data);
        } catch (error) {
            console.error('Error fetching sentences:', error);
        }
    };

    const onStartTest = () => {
            navigate(TEST)
    }

    function InstructionList() {
        const instructions = [];
        for (let i = 1; i <= 6; i++) {
            const instructionKey = `instructions${i}`;
            const instructionText = instructionsData[instructionKey];
            instructions.push(
                <div key={instructionKey} style={{ marginBottom: 10 }}>
                    <strong>{'Step'} {i}:</strong>
                    <div>{parse(instructionText)}</div>
                </div>
            );
        }
        return instructions
    }

    const InstructionComp = () => (
        <>
            <div className={homeclass.instructionHeading}>Instructions</div>
            <div className={homeclass.instructionNote}>Please read carefully before continuing.</div>
            <Alert sx={{ marginBottom: 5, fontSize: 16, textAlign: 'left', background: '#F2FBFC' }} icon={false} >
                <div>
                    {InstructionList()}
                </div>
            </Alert>
        </>
    )

    const btnData = {
        btnName: "Start Task",
        width: 300,
        height: 50
    }
    return (
        <div>
            {
                isMobile ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', margin: 0 }}>
                        Please take this task on your desktop or laptop.
                    </div>
                )
                    : (
                        <div className={homeclass.homeContainer}>
                            <div className={homeclass.homeContentWrap}>
                                <div style={{ width: 'calc(100vw - 10rem)' }} >
                                    <div className={homeclass.infoWrap}>
                                    </div>
                                    <div>
                                        <Paper sx={{ padding: 5 }}>
                                            <InstructionComp />
                                        </Paper>
                                    </div>
                                </div>
                                <div className={homeclass.startBtnWrap}>
                                    <Button
                                        btnData={btnData}
                                        handleBtnClick={() => onStartTest()}
                                    />
                                </div>
                            </div>
                        </div >
                    )
            }
        </div>
    )
}

export default Home


