import * as React from 'react';
import Button from '@mui/material/Button';

const BasicButton = ({ btnData, handleBtnClick, handleEnter, isDisabled }) => {

    return (
        <Button
            type="submit"
            variant="contained"
            onClick={handleBtnClick}
            disabled={isDisabled}
            sx={{
                background: '#00A6C7',
                width: btnData.width,
                fontWeight: "bold",
                color: '#F5F5F5',
                height: btnData.height || 'inherit',
                '&:hover': {
                    backgroundColor: '#00A6C7', // Change this to your desired hover color
                },
            }}
        >
            {btnData.btnName}
        </Button>
    );
}

export default BasicButton
