import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './store/configureStore';
import initialState from './store/reducers/initialState';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';  // Ensure the path to aws-exports is correct

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore(initialState);

Amplify.configure(awsconfig);
console.log = function() {};
console.warn = function() {};
console.error = function() {};
root.render(
  <Router>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
