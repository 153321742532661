import React from 'react';
import Paper from '@mui/material/Paper';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'lightgrey',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#4db7bc',
    },
}));

const ValueDisplay = styled(Box)(({ theme }) => ({
    backgroundColor: '#f5f5f5',
    padding: '5px 10px',
    borderRadius: '20px',
    fontWeight: 600,
    fontSize: '14px',
    color: '#333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '50px',
}));

const Marker = styled(Box)(({ theme }) => ({
    fontSize: '10px',
    color: '#333',
    fontWeight: 600,
}));

const ProgressBar = ({ val, day }) => {
    return (
        <div style={{ width: '80%', padding: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ fontSize: 16, fontFamily: 'Segoe UI', fontWeight: 'bold', color: '#333333' }}>
                    Day - {day}
                </div>
                <ValueDisplay>
                    {val} {(val === 1) ? 'Sentence' : 'Sentences'}
                </ValueDisplay>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Marker>0</Marker>
                <div style={{ flexGrow: 1, margin: '0 10px' }}>
                    <BorderLinearProgress variant="determinate" value={val * 10} />
                </div>
                <Marker>10</Marker>
            </div>
            {/* <BorderLinearProgress variant="determinate" value={val * 10} /> */}
        </div>
    );
}

export default ProgressBar;
