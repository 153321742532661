import React from 'react'
import { useLottie } from "lottie-react";
import trophy from '../assets/trophy.json'

const Animator = ({ val }) => {
    const options = {
        animationData: trophy,
        loop: false
    };

    const { View } = useLottie(options);
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <div style={{ width: '15%', height: '100px' }}>{View}</div>
        </div>
    )
}

export default Animator
