import React, { useState, useEffect, useRef } from 'react';
import Button from '../UI/Button';
import api from '../apiSetup'
import styles from './finaltask.module.css'
import { calcAvgIKI } from '../Utility/performanceCalc'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGIN, RESULTS } from '../RouteConst';
import VerticalProgress from '../UI/VerticalProgress'
import { setSentences, setResultData, setPrevResult, setIsAuth } from '../store/actions/homeActions';
import Loader from '../UI/Loader'
import { debounce } from 'lodash';
import Animator from './Animator'
import ProgressDisplay from '../UI/ImgProgress'
import parse from 'html-react-parser';

let keys = {};
let logDataToBeSubmitted = []
const day = 'day_01';

const PilotTask = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const inputRef = useRef(null);
    const overlayRef = useRef(null)
    const imageArr = useSelector(state => state.home.imageList);
    const sentenceArr = useSelector(state => state.home.sentenceList);
    const pid = useSelector(state => state.home.pid);
    const pType = useSelector(state => state.home.pType);
    const sid = useSelector(state => state.home.sid);
    const sesCount = useSelector(state => state.home.sesCount);
    const baseIKI = useSelector(state => state.home.baseIKI);

    // console.log(imageArr, "imageArr")
    const [firstKeyPressTime, setFirstKeyPressTime] = useState(null);
    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);
    const [phase, setPhase] = useState(0);
    const [showOverlay, setOverlay] = useState(false);
    const [isTypingEnabled, setIsTypingEnabled] = useState(true);
    const [wordCount, setWordCount] = useState(0);
    const [ikiThreshold, setIKIThreshold] = useState(null);
    const [imageList, setImages] = useState([]);
    const [sentenceList, setSentenceList] = useState([]);

    const [imgIndex, setImgIndex] = useState(0)
    const [sentenceIndex, setSentenceIndex] = useState(0)
    const [inputVal, setInputVal] = useState('')
    const [tsId, setTsId] = useState(null)
    const [ikiList, setikiList] = useState([])
    const [progressVal, setProgressVal] = useState(0)
    const [errorRateList, setErrRateList] = useState([])
    const [isSentence, setIsSentence] = useState(false)
    const [isLoader, setLoader] = useState(false)
    const [isAnimation, setAnimation] = useState(false)
    // const [taskComplete, setTaskComplete] = useState(false)
    const taskComplete = useRef(false);
    // const taskCompleteRef = useRef(taskComplete);


    useEffect(() => {
        if (isAnimation) {
            setTimeout(() => {
                setAnimation(false)
            }, 4000);
        }
    }, [isAnimation])



    useEffect(() => {
        if (sesCount > 0) {
            console.log(baseIKI)
            setIKIThreshold(baseIKI)
        }
    }, [sesCount, baseIKI])

    useEffect(() => {
        // console.log(sentenceArr, "sentenceArr")
        setImages(imageArr)
        setSentenceList(sentenceArr)
    }, [imageArr]);

    const addTestSection = async (isFirstSentence, imageList) => {
        // console.log(phase)
        // console.log(sentenceIndex, phase, imgIndex)
        const phaseVal = (pType === 'composition' && (imgIndex === 5 || imgIndex === 15)) ? phase + 1
            : (pType === 'transcription' && ((isSentence && sentenceIndex === 0) || (!isSentence && imgIndex === 5))) ? phase + 1 : phase
        const tsData = {
            imgId: isSentence ? "na" : imageList[imgIndex],
            sentenceId: isSentence ? sentenceList[sentenceIndex] : "na",
            participantId: pid,
            sid: sid,
            phase: phaseVal
            // ((imgIndex === 5 || imgIndex === 15) || (sentenceIndex === 0 || sentenceIndex === 9)) ? (phase + 1) : phase
        }

        try {
            const response = await api.post('/addTestSection', tsData);
            if (response.data.id) {
                // setTsId(response.data.id)
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject("Response does not contain test section ID");
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    useEffect(() => {
        if (imgIndex === 0) {
            // console.log("test section")
            addTestSection(true, imageArr).then((testSectionId) => {
                setTsId(testSectionId)
            }).catch((error) => {
                // Handle error if needed
                console.error('Error:', error);
            });
        }
        const handleBeforeUnload = (e) => {
            console.log("before unload")
            e.preventDefault();
            e.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // This is the component will unmount logic
            // Here we reset the isAuth state to false
            window.removeEventListener('beforeunload', handleBeforeUnload);
            console.log(taskComplete.current)
            if (taskComplete.current) {
                navigate(RESULTS)
            } else {
                dispatch(setIsAuth(false));
                navigate(LOGIN); // Redirect to login or another page if needed
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // console.log(imgIndex, "imgindex")
        let maxImgIndex = (pType === 'composition') ? 20 : 10
        if (imgIndex > 0 && imgIndex < maxImgIndex) {
            // console.log("GOING HERE")
            // console.log(imgIndex, "imgIndex")
            addTestSection(false, imageArr).then((testSectionId) => {
                // Do something with the test section ID if needed
                // console.log(testSectionId, "NEW TEST SECTION ID")
                setTsId(testSectionId)
            }).catch((error) => {
                // Handle error if needed
                console.error('Error:', error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgIndex])

    useEffect(() => {
        if (isSentence) {
            // console.log("test section")
            addTestSection(true, sentenceArr).then((testSectionId) => {
                setTsId(testSectionId)
            }).catch((error) => {
                // Handle error if needed
                console.error('Error:', error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSentence]);

    useEffect(() => {
        // else if (pType === 'transcription') {
        if (isSentence && sentenceIndex >= 0 && sentenceIndex < 10) {
            // console.log("GOING HERE")
            // console.log(imgIndex, "imgIndex")
            addTestSection(false, sentenceArr).then((testSectionId) => {
                // Do something with the test section ID if needed
                // console.log(testSectionId, "NEW TEST SECTION ID")
                setTsId(testSectionId)
            }).catch((error) => {
                // Handle error if needed
                console.error('Error:', error);
            });
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentenceIndex])




    useEffect(() => {
        const overlayEle = overlayRef.current;
        const handleOverlayKeyDown = (e) => {
            if (showOverlay && e.which === 13) {
                e.preventDefault()
                onStartTyping()
            }
        }

        if (showOverlay && overlayEle) {
            overlayEle.focus();
            overlayEle.addEventListener('keydown', handleOverlayKeyDown);
            // inputElement.addEventListener('keyup', handleKeyUp);
        }

        if (showOverlay) {
            setIsTypingEnabled(false);
            setLoader(false)
        }

        return () => {
            if (overlayEle) {
                overlayEle.removeEventListener('keydown', handleOverlayKeyDown);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOverlay])


    useEffect(() => {
        if (!showOverlay && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showOverlay]);


    function whichPressed(keys) {
        var pressed = [];
        for (var k in keys) {
            if (keys[k] === true) {
                pressed.push(k);
            }
        }
        return pressed;
    }

    const getEvtData = (testSecId, event, time, inputVal, inputEvt) => {
        if (testSecId) {
            const evtObj = {
                testSectionId: testSecId, // Error To Do : first evtObj has testSectionId value as null
                eventTimestamp: time,
                eventType: String(event.type == null ? '' : event.type), // catches both null and undefined
                eventKey: String(event.key == null ? '' : event.key), // catches both null and undefined
                eventCode: String(event.code == null ? '' : event.code), // catches both null and undefined
                eventData: inputEvt ? String(event.nativeEvent.data == null ? '' : event.nativeEvent.data) : String(event.data == null ? '' : event.data), // catches both null and undefined
                inputText: String(inputVal),
                keyCodes: String(whichPressed(keys)),
                sid: sid
            }
            return evtObj
        } else {
            // console.log("test section id is null")
        }
    }
    const handleKeyLog = async (e) => {
        const currentTime = Date.now();
        if (firstKeyPressTime === null) {
            setFirstKeyPressTime(Date.now())
        }
        setLastKeyPressTime(Date.now())
        const evtData = getEvtData(tsId, e, currentTime, inputVal, false)
        if (evtData) {
            logDataToBeSubmitted.push(evtData);
        }
    };

    useEffect(() => {
        const inputElement = inputRef.current;

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                // e.preventDefault()
                handleNext(e.srcElement.value, true)
            } else {
                keys[e.which] = true;
                handleKeyLog(e)
            }
        }

        const handleKeyUp = (e) => {
            if (e.key !== 'Enter') {
                keys[e.which] = false;
                handleKeyLog(e)
            }
        }

        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
            inputElement.addEventListener('keyup', handleKeyUp);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
                inputElement.removeEventListener('keyup', handleKeyUp);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tsId, firstKeyPressTime, lastKeyPressTime]);


    const onStartTyping = () => {
        if (phase < 4) {
            setPhase(phase + 1)
        }
        setIsTypingEnabled(true);
        setOverlay(false);
    };

    const submitLogData = async () => {
        if (logDataToBeSubmitted.length > 0) {
            // console.log(logDataToBeSubmitted, "LOG DATA")
            try {
                const response = await api.post('/storeLogData', logDataToBeSubmitted);
                return true
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    const countWords = (text) => {
        return text.trim().split(/\s+/).length;
    };


    const onInputChange = (e) => {
        e.preventDefault();
        const newValue = e.target.value;
        const newWordCount = countWords(newValue);
        setWordCount(newWordCount); // Update word count
        setInputVal(newValue);
        const evtData = getEvtData(tsId, e, Date.now(), newValue, true);
        if (evtData) {
            logDataToBeSubmitted.push(evtData);
        }
        e.preventDefault()
        // const evtData = getEvtData(tsId, e, Date.now(), e.target.value, true)
        // if (evtData) {
        //     logDataToBeSubmitted.push(evtData);
        // }
        // setInputVal(e.target.value)
    };

    const overlayText = {
        0: "Task: Type the next 10 sentences as fast as you can.<br/> Click on the Start button below or Enter key to get started.",
        // <br/> <br/>Note: Think about the sentence you want to type before you start typing.This helps improve typing accuracy and flow.
        1: "Now you can continue typing normally",
    }

    const updateBaseIKI = async (threshIkI) => {
        const data = {
            pid: pid,
            threshIkI: threshIkI
        }
        try {
            const response = await api.post('/storeBaseIKI', data);
            return true
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const fetchResultData = async () => {
        try {
            const response = await api.get(`/fetch_result_data?uid=${pid}&baseIKI=${ikiThreshold}`);
            dispatch(setPrevResult(response.data.fast_sentences))
            taskComplete.current = true
            // setTaskComplete(true)
            navigate(RESULTS)
            console.log(response)
            return true
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const submitUserInput = async (inputValue, ikival) => {
        if (inputValue.length > 0) {
            const userInputData = {
                tsId: tsId,
                inputVal: inputValue,
                ikival: ikival
            }

            try {
                const resp = await api.post('/submitUserInput', userInputData);
                if (resp.status === 200) {
                    setInputVal('')
                    setWordCount(0)
                    setFirstKeyPressTime(null)
                    setLastKeyPressTime(null)
                    if (pType === 'composition') {
                        if (imgIndex < 20) {
                            logDataToBeSubmitted = []
                            setTsId(null)
                            if (imgIndex === 4 || imgIndex === 14) {
                                setOverlay(true)
                                if (imgIndex === 4) {
                                    const thresholdIKI = ikiList.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / ikiList.length;
                                    if (sesCount === 0) {
                                        updateBaseIKI(thresholdIKI)
                                    }
                                    setIKIThreshold(thresholdIKI)
                                } else {
                                    setAnimation(true)
                                }
                            }
                            setImgIndex(imgIndex + 1)
                            if (imgIndex === 19) {
                                if (sesCount > 0) {
                                    dispatch(setResultData(progressVal / 10))
                                    fetchResultData()
                                } else {
                                    // const resData = {
                                    //     fastSentenceCount: progressVal / 10
                                    // }
                                    dispatch(setResultData(progressVal / 10))
                                    taskComplete.current = true
                                    // setTaskComplete(true)
                                    navigate(RESULTS)
                                }
                            }
                            // setTimeout(() => {
                            // }, 3000);

                        }
                    } else if (pType === 'transcription') {
                        if (imgIndex < 10) {
                            logDataToBeSubmitted = []
                            setTsId(null)
                            if (isSentence) {
                                if (sentenceIndex === 9) {
                                    setOverlay(true)
                                    setIsSentence(false)
                                    setImgIndex(imgIndex + 1)
                                } else {
                                    setAnimation(true)
                                }
                                setSentenceIndex(sentenceIndex + 1)
                                setLoader(false)
                            } else {
                                if (imgIndex === 4) {
                                    setOverlay(true)
                                    const thresholdIKI = ikiList.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / ikiList.length;
                                    if (sesCount === 0) {
                                        updateBaseIKI(thresholdIKI)
                                    }
                                    setIKIThreshold(thresholdIKI)
                                    setIsSentence(true)
                                } else {
                                    setImgIndex(imgIndex + 1)
                                }
                            }
                            if (imgIndex === 9) {
                                if (sesCount > 0) {
                                    dispatch(setResultData(progressVal / 10))
                                    fetchResultData()
                                } else {
                                    dispatch(setResultData(progressVal / 10))
                                    taskComplete.current = true
                                    // setTaskComplete(true)
                                    navigate(RESULTS)
                                }
                            }
                        }
                    }
                    // setLoader(false)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleNext = (inputData, isEnter) => {
        // console.log(sentenceIndex, inputVal, "CAME HERE")
        if (!isSentence && wordCount < 5) return;
        setLoader(true)
        const ikival = calcAvgIKI(logDataToBeSubmitted)
        const tempikiList = JSON.parse(JSON.stringify(ikiList))
        tempikiList.push(ikival)
        setikiList(tempikiList)

        if (pType === 'composition' && imgIndex > 4 && imgIndex < 15) {
            if (tempikiList[imgIndex] < ikiThreshold) {
                console.log(progressVal, "img prog")
                setProgressVal(progressVal + 10)
            }
        } else if (isSentence && sentenceIndex >= 0 && sentenceIndex < 10) {
            if (tempikiList[sentenceIndex + 5 ] < ikiThreshold) {
                console.log(progressVal, "sentence prog")
                setProgressVal(progressVal + 10)
            }
        }

        const result = submitLogData()
        const inputValue = isEnter ? inputData : inputVal
        result.then(() => {
            // The promise has resolved
            submitUserInput(inputValue, ikival);
        });
    }

    const getAnimationTxt = () => {
        const val = progressVal / 10
        if (val > 8) {
            return "Excellent!"
        } else if (val > 4) {
            return "Good job!"
        } else if (val < 5) {
            return "Nice try, Better luck next time!"
        }
    }

    const onImgLoad = () => {
        setLoader(false)
        inputRef.current.focus();
        console.log("Img load", isLoader)
    }

    return (
        <div
            style={{
                opacity: isLoader ? 0.4 : 'unset',
                pointerEvents: isLoader ? 'none' : 'auto',  // 'auto' restores default behavior when the loader is off
                userSelect: isLoader ? 'none' : 'auto',     // Prevents text selection
            }}
            tabIndex={isLoader ? -1 : 0}                  // Prevents focusing on elements inside when the loader is on
            aria-hidden={isLoader ? 'true' : 'false'}
        >
            {isLoader && <Loader />}

            {showOverlay ? (
                <div style={overlayStyle} ref={overlayRef} tabIndex="0">
                    <div
                        style={countdownStyle}>
                        {
                            isAnimation ? (
                                <div>
                                    <Animator val={(progressVal / 10)} />
                                    <div style={{ fontWeight: 'bold', color: 'yellow' }}>
                                        {getAnimationTxt()}
                                        < br />
                                        {((progressVal / 10) > 0) && `You typed ${progressVal / 10} sentences with high speed!`}
                                    </div>
                                    {/* <VerticalProgress progress={progressVal} isOverlay={true} /> */}
                                </div>
                            )
                                : (
                                    <>
                                        <div>{parse(overlayText[phase])} </div>
                                        {<Button btnData={overlayBtnData} handleBtnClick={() => onStartTyping()} />}
                                    </>
                                )
                        }

                    </div>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: 100 }}>
                    <ProgressDisplay current={(pType === 'composition') ? imgIndex + 1 : (imgIndex + sentenceIndex + 1)} />
                    <div style={{ marginLeft: '2%', fontSize: 20, fontWeight: 'bold', color: '#00A6C7' }}>
                        {(!isSentence && phase !== 1)
                            ? 'Describe the image in your own words. Think about what you want to type before you start typing.'
                            : (isSentence) ? 'Transcribe these sentences as fast as you can.'
                                : (!isSentence && phase === 1) ? 'Describe the image in the highest speed possible. Think about what you want to type before you start typing.'
                                    : ''
                        }
                    </div>
                </div>
            )
            }
            {
                (!showOverlay && phase === 1) &&
                <div style={{ position: 'absolute', right: 50 }}>
                    <VerticalProgress progress={progressVal} isOverlay={false} />
                </div>
            }
            {
                isSentence ? (
                    <div style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 28, marginTop: '10%', marginBottom: 30, fontFamily: 'monospace'
                    }}>
                        <div>{(sentenceList.length > 1) && sentenceList[sentenceIndex]}</div>
                    </div>
                )
                    : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ height: '300px', width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    src={(imageList.length > 1) && imageList[imgIndex]}
                                    alt="task_1_img"
                                    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                                    onLoad={() => onImgLoad()}
                                />
                            </div>
                        </div>
                    )
            }
            <div style={{ display: 'flex', flexDirection: 'column', padding: "0px 180px" }}>
                {
                    (phase !== 4) &&
                    <input
                        inputRef={input => input && input.focus()}
                        inputProps={{ style: { fontSize: 22, lineHeight: 1 } }}
                        ref={inputRef}
                        multiline
                        rows={1}
                        variant="outlined"
                        value={inputVal}
                        onChange={(e) => onInputChange(e)}
                        fullWidth
                        disabled={!isTypingEnabled}
                        autoFocus
                        style={{
                            width: '100%',
                            height: 40,
                            fontSize: 28,
                            fontFamily: 'monospace'
                        }}
                    />
                }
            </div>
            <div style={{ textAlign: 'right', marginRight: 180 }}>{!isSentence && `${wordCount} words`}</div>
            <div style={{ float: 'right', marginRight: 180, marginTop: 20 }}>
                <Button btnData={btnData} handleBtnClick={() => handleNext(null, false)}
                    isDisabled={!isSentence && wordCount < 5}
                />
            </div>

        </div>
    );
};

const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
};

const countdownStyle = {
    color: 'white',
    fontSize: '3em',
};

const overlayBtnData = {
    btnName: "START",
    width: 125
};

const btnData = {
    btnName: "Next",
    width: 125
}


export default PilotTask;

