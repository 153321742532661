import React, { useState, useEffect } from 'react';
import api from '../apiSetup';
import { useNavigate } from 'react-router-dom';
import { HOME, TASK_COMPLETE } from '../RouteConst';
import { useDispatch } from 'react-redux';
import { setParticipantId, resetState } from '../store/actions/homeActions';
import './Login.css';  // Import the CSS file
import Button from '../UI/Button'

function Login() {
  const [userId, setUserId] = useState('');
  const [error, setError] = useState(null);
  const [taskDone, setTaskDone] = useState(false);
  const [message, setMessage] = useState('');
  const [sCount, setSCount] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleLogin = async () => {
    if (userId.length === 6) {
      try {
        const response = await api.post('/login', { participant_id: userId });
        if (response.status === 200) {
          if (response.data.same_day_session_complete || response.data.session_count === 10) {
            setTaskDone(true)
            if (response.data.session_count === 10) {
              setMessage("All done! Please contact the experimenter to know the next steps.")
            } else {
              setMessage(response.data.message)
            }
            setSCount(response.data.session_count)
            // navigate(TASK_COMPLETE)
            console.log("SAME DAY SESSION COMPLETE")
          } else {
            console.log(response.data.participant_type)
            const userData = {
              isAuth: true,
              userId: userId,
              participant_type: response.data.participant_type,
              session_count: response.data.session_count || 0,
              baseIKI: response.data.base_iki || 0
            }
            dispatch(setParticipantId(userData));
            navigate(HOME);
            // }
          }
        }
      } catch (err) {
        setError('User not found');
      }
    } else {
      setError('Invalid Id!');
    }
  };

  return (
    <div>
      {
        taskDone ? (
          <div className="task-complete-container">
            <div className="task-complete-content">
              <h1 className="task-complete-title">{(sCount && sCount === 10) ? 'Congratulations! You have successfully completed the user study' : `Today's Writing Task is Complete!`}</h1>
              <p className="task-complete-message">
                {message}
              </p>
              {/* <button className="task-complete-button" onClick={handleGoHome}>
              Go to Home
            </button> */}
            </div>
          </div>
        ) :
          (
            <div className="login-wrap">
              <div className="login-container">
                <h2>Login</h2>
                {/* <div style={{ textAlign: 'left', fontSize: 12, fontWeight: 'bold', paddingBottom: 5, color: '#333' }}>User Name:</div> */}
                <input
                  type="text"
                  placeholder="Enter 6-Digit Code"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
                {<Button btnData={btnData} handleBtnClick={() => handleLogin()}/>}
                {error && <p className="error-message">{error}</p>}
                {/* <button onClick={handleLogin}>Login</button> */}
              </div>
            </div>
          )
      }
    </div>
  );
}
const btnData = {
  btnName: "Continue",
  width: '100%'
}

export default Login;

// import React, { useState, useEffect } from 'react';
// import api from '../apiSetup'
// import { useNavigate } from 'react-router-dom';
// import { HOME } from '../RouteConst';
// import { useSelector, useDispatch } from 'react-redux';
// import { setParticipantId } from '../store/actions/homeActions'

// function Login() {
//   const [userId, setUserId] = useState('A1B2C3');
//   const [error, setError] = useState(null);
//   // const [progress, setProgress] = useState(5);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleLogin = async () => {
//     if (userId.length === 6) {
//       try {
//         const response = await api.post('/login', { participant_id: userId });
//         console.log(response.status)
//         if (response.status === 200) {
//           dispatch(setParticipantId(userId))
//           navigate(HOME);
//         }
//         // alert(response.data);
//         // Redirect or handle successful login
//       } catch (err) {
//         setError('User not found');
//       }
//     } else {
//       setError('Invalid Id!');
//     }
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <input
//         type="text"
//         placeholder="Enter 6-Digit Code"
//         value={userId}
//         onChange={(e) => setUserId(e.target.value)}
//       />
//       <button onClick={handleLogin}>Login</button>
//       {error && <p style={{ color: 'red' }}>{error}</p>}

//     </div>
//   );
// }

// export default Login;
