// components/TaskCompletePage.js
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import './TaskComplete.css'; // Import the CSS file for styling

const TaskCompletePage = () => {
  // const navigate = useNavigate();

  // const handleGoHome = () => {
  //   navigate('/home');
  // };

  return (
    <div className="task-complete-container">
      <div className="task-complete-content">
        <h1 className="task-complete-title">Today's Writing Task is Complete!</h1>
        <p className="task-complete-message">
          Thank you for participating! Please come back tomorrow for the next task.
        </p>
        {/* <button className="task-complete-button" onClick={handleGoHome}>
          Go to Home
        </button> */}
      </div>
    </div>
  );
};

export default TaskCompletePage;
