import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as ROUTES from './RouteConst';
import Home from './Components/Home';
import Test from './Components/UserStudy';
import Login from './Components/Login';
import Results from './Components/Results';
import TaskComplete from './Components/TaskComplete';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AppRouter = () => {
  
  const PrivateRoute = ({ children }) => {
    const isAuth = useSelector(state => state.home.isAuth); // Assuming you store it in Redux
  
    return isAuth ? children : <Navigate to="/" />;
  };
  
  return (
    <Routes>
      <Route exact path={ROUTES.LOGIN} element={<Login />} />
      <Route exact path={ROUTES.HOME} element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route exact path={ROUTES.TEST} element={<PrivateRoute><Test /></PrivateRoute>} />
      <Route exact path={ROUTES.RESULTS} element={<PrivateRoute><Results /></PrivateRoute>} />
      <Route exact path={ROUTES.TASK_COMPLETE} element={<TaskComplete />} />
    </Routes>
  );
};

export default AppRouter;
