import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../Utility/Utility';
import initialState from './initialState';

export const setStoreImgs = (state, action) => {
  return (
    updateObject(state, {
      imageList: action.data,
    })
  )
};

export const setParticipantId = (state, action) => {
  return (
    updateObject(state, {
      isAuth: action.data.isAuth,
      pid: action.data.userId,
      pType: action.data.participant_type,
      sesCount: action.data.session_count,
      baseIKI: action.data.baseIKI
    })
  )
};


export const setSessionId = (state, action) => {
  return (
    updateObject(state, {
      sid: action.data,
    })
  )
};

export const setSessionCount = (state, action) => {
  return (
    updateObject(state, {
      sesCount: action.data,
    })
  )
};

export const setSentences = (state, action) => {
  return (
    updateObject(state, {
      sentenceList: action.data,
    })
  )
};

export const setResultData = (state, action) => {
  return (
    updateObject(state, {
      fastSentences: action.data,
    })
  )
};

export const setPrevResult = (state, action) => {
  return (
    updateObject(state, {
      prevResult: action.data,
    })
  )
};

export const setIsAuth = (state, action) => {
  return (
    updateObject(state, {
      isAuth: action.data,
    })
  )
};

const homeReducer = (state = initialState.home, action) => {
  switch (action.type) {
    case actionTypes.SET_STORE_IMAGES: return setStoreImgs(state, action);
    case actionTypes.SET_PARTICIPANT_ID: return setParticipantId(state, action);
    case actionTypes.SET_SESSION_ID: return setSessionId(state, action);
    case actionTypes.SET_SESSION_COUNT: return setSessionCount(state, action);
    case actionTypes.SET_SENTECES: return setSentences(state, action);
    case actionTypes.SET_RESULT_DATA: return setResultData(state, action);
    case actionTypes.SET_PREV_RESULT: return setPrevResult(state, action);
    case actionTypes.SET_IS_AUTH: return setIsAuth(state, action);
    case actionTypes.RESET_STATE: return initialState.home;
    default:
      return state;
  }
};

export default homeReducer;
