import * as actionTypes from './actionTypes';

export const setStoreImgs = (data) => ({
  type: actionTypes.SET_STORE_IMAGES,
  data
});

export const setParticipantId = (data) => ({
  type: actionTypes.SET_PARTICIPANT_ID,
  data
});

export const setSessionId = (data) => ({
  type: actionTypes.SET_SESSION_ID,
  data
});


export const setSessionCount = (data) => ({
  type: actionTypes.SET_SESSION_COUNT,
  data
});

export const setSentences = (data) => ({
  type: actionTypes.SET_SENTECES,
  data
});

export const setResultData = (data) => ({
  type: actionTypes.SET_RESULT_DATA,
  data
});

export const setPrevResult = (data) => ({
  type: actionTypes.SET_PREV_RESULT,
  data
});

export const setIsAuth = (data) => ({
  type: actionTypes.SET_IS_AUTH,
  data
});

export const resetState = () => ({
  type: actionTypes.RESET_STATE
});
