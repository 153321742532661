import React from "react";
import { useSpring, animated } from "react-spring";
import "./styles.css";

const VerticalProgress = ({ progress, isOverlay }) => {
  // Animation configuration
  const props = useSpring({
    to: { height: `${progress}%` },
    from: { height: "0%" },
    config: { duration: 600 },  // Duration of the animation
  });

  return (
    <div>
      <div style={{ marginBottom: 5, fontSize: 14, fontWeight: 'bold' }}>Fast Typed Sentences</div>
      {/* {!isOverlay && <div style={{ fontSize: 14, textAlign: 'left' }}>{progress}</div>} */}
      <div className="progress vertical">
        <animated.div className="progress-bar" style={props}>
          {!isOverlay && <span className="progress-text" style={{ color: '#F5F5F5' }}>{progress / 10}</span>}
        </animated.div>
      </div>
    </div>

  );
};

export default VerticalProgress;
