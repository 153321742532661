export const calcAvgIKIPerSentPerPhase = (logdata) => {
   // Step 1: Filter keydown events
   const keydownEvents = logdata.filter(event => event.eventType === 'keydown');

   // Step 2: Group keydown events by sentence_index
   const groupedEvents = keydownEvents.reduce((acc, event) => {
       const { sentence_index, eventTimestamp } = event;
       if (!acc[sentence_index]) {
           acc[sentence_index] = [];
       }
       acc[sentence_index].push(eventTimestamp);
       return acc;
   }, {});

   // Step 3: Calculate IKIs and average IKI per sentence
   const avgIKIs = Object.keys(groupedEvents).reduce((acc, sentenceIndex) => {
       const timestamps = groupedEvents[sentenceIndex];
       if (timestamps.length < 2) {
           // If there are fewer than 2 keydowns, we can't calculate IKIs
           return acc;
       }

       // Calculate IKIs and filter by threshold
       const ikis = [];
       for (let i = 1; i < timestamps.length; i++) {
           const iki = timestamps[i] - timestamps[i - 1];
        //    if (iki < ikiThreshold) {
               ikis.push(iki);
        //    }
       }

       if (ikis.length === 0) {
           // No IKIs within the threshold
           acc[sentenceIndex] = 0; // Or handle as appropriate
           return acc;
       }

       // Calculate average IKI
       const sum = ikis.reduce((total, iki) => total + iki, 0);
       const avgIKI = sum / ikis.length;
       
       acc[sentenceIndex] = avgIKI;
       return acc;
   }, {});

   console.log(avgIKIs, "Average IKI per sentence");
   return avgIKIs;
}

// old code
export const calcAvgIKI = (logdata) => {
    // Step 1: Filter keydown events for the specific sentence index
    // const keydownEvents = logdata.filter(event => event.eventType === 'keydown' && event.sentence_index === sentenceCount);
    const keydownEvents = logdata.filter(event => event.eventType === 'keydown');

    // If there are fewer than 2 keydown events, return 0 (or handle as appropriate)
    if (keydownEvents.length < 2) {
        return 0 
        // return { [sentenceCount]: 0 };
    }

    // Step 2: Extract the timestamps
    const timestamps = keydownEvents.map(event => event.eventTimestamp);

    // Step 3: Calculate IKIs
    const ikis = [];
    for (let i = 1; i < timestamps.length; i++) {
        const iki = timestamps[i] - timestamps[i - 1];
        ikis.push(iki);
    }

    // Step 4: Calculate the average IKI
    const sum = ikis.reduce((total, iki) => total + iki, 0);
    const avgIKI = sum / ikis.length;
    return avgIKI
    // Return the sentence index mapped to the average IKI
    // return { [sentenceCount]: avgIKI };
};


export const calcThresholdIKI = (logData) => {
    // console.log(logData, "util")

    // Step 1: Extract the timestamps of the keydown events
    const keydownEvents = logData.filter(event => event.eventType === 'keydown');
    const keydownTimestamps = keydownEvents.map(event => event.eventTimestamp);
    // Step 2: Calculate the IKIs
    const IKIs = [];
    for (let i = 1; i < keydownTimestamps.length; i++) {
        IKIs.push(keydownTimestamps[i] - keydownTimestamps[i - 1]);
    }

    // Step 3: Compute the mean of the IKIs
    const meanIKI = IKIs.reduce((sum, iki) => sum + iki, 0) / IKIs.length;

    // Step 4: Compute the standard deviation of the IKIs
    const squaredDiffs = IKIs.map(iki => Math.pow(iki - meanIKI, 2));
    const variance = squaredDiffs.reduce((sum, squaredDiff) => sum + squaredDiff, 0) / IKIs.length;
    const stdDevIKI = Math.sqrt(variance);

    const ikiThreshold = (meanIKI + (2 * stdDevIKI))
    return ikiThreshold;
    // console.log(ikiThreshold, meanIKI, stdDevIKI, "ikiThreshold")
}

// export const calculateAverageIKI = (keylogs) => {
//     // Filter only 'keydown' events
//     const keydownEvents = keylogs.filter(log => log.eventType === 'keydown');

//     // Check if there are at least two keydown events to calculate IKI
//     if (keydownEvents.length < 2) {
//         return {
//             averageIKI: 0,
//             ikis: []
//         };
//     }

//     // Calculate IKI for the given keylogs
//     const ikis = [];
//     for (let i = 1; i < keydownEvents.length; i++) {
//         const previousTimestamp = keydownEvents[i - 1].eventTimestamp;
//         const currentTimestamp = keydownEvents[i].eventTimestamp;
//         const iki = currentTimestamp - previousTimestamp;
//         ikis.push(iki);
//     }

//     // Calculate average IKI
//     const totalIKI = ikis.reduce((sum, iki) => sum + iki, 0);
//     const averageIKI = totalIKI / ikis.length;

//     return {
//         averageIKI,
//         ikis
//     };
// };
