import axios from 'axios';


// const baseURL =  "https://3.95.188.77"
// "https://n59jgp3yo8.execute-api.us-east-1.amazonaws.com/"

// 'http://127.0.0.1:8000'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://typinginterventions.de/api' : 'http://127.0.0.1:8000'

const api = axios.create({
  baseURL
});

export default api