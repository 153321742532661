import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ResultProgress from '../UI/ResultProgress';
import './Result.css';
import ResultAnimation from './ResultAnimation';
import PrevProgress from '../UI/ProgressBar';
import api from '../apiSetup'

const Results = () => {
  const pid = useSelector(state => state.home.pid);
  const sid = useSelector(state => state.home.sid);
  const sCount = useSelector(state => state.home.sesCount);
  const fastSentenceCount = useSelector(state => state.home.fastSentences);
  const prevResult = useSelector(state => state.home.prevResult);

  const [dayMessage, setDayMessage] = useState('');
  const [prevRes, setPrevResult] = useState([]);

  const getOrdinal = (num) => {
    if (num > 10 && num < 20) return num + 'th';
    switch (num % 10) {
      case 1: return num + 'st';
      case 2: return num + 'nd';
      case 3: return num + 'rd';
      default: return num + 'th';
    }
  };

  const updateSessionStatus = async (fsc) => {
    const sessionData = {
      pid: pid,
      sid: sid,
      sCount: sCount + 1,
      isComplete: true,
      fast_sentences: fsc,
    };

    try {
      await api.post('/updateSessionStatus', sessionData);
    } catch (error) {
      console.error('Error updating session status:', error);
    }
  };

  useEffect(() => {
    const dayNumber = sCount + 1;
    (sCount + 1) < 10 ? setDayMessage(`Your ${getOrdinal(dayNumber)} day's writing task is complete. See you tomorrow !`) : setDayMessage(`Your ${getOrdinal(dayNumber)} day's writing task is complete!`)
    if (fastSentenceCount >= 0) {
      updateSessionStatus(fastSentenceCount);
    }
    if (prevResult.length > 0) {
      setPrevResult(prevResult);
    }
  }, [fastSentenceCount, prevResult, sCount]);

  return (
    <div style={{ margin: 50 }}>
      <div style={{ marginTop: 10 }}>
        <p className='result-message'>{dayMessage}</p>
        <ResultAnimation />
      </div>
      <p>{/* Optional performance message can be added here */}</p>
      <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{
          marginRight: 20,
          fontSize: 16,
          fontFamily: 'Segoe UI',
          fontWeight: 'bold',
          color: '#333333',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
          marginBottom: 10
        }}>
          Fast Sentences Typed Today: <span style={{ color: '#008080', fontWeight: 'bolder', marginLeft: 5 }}>{fastSentenceCount}</span>
        </div>
        <ResultProgress current={fastSentenceCount} goal={10} />
      </div>
      {
        (prevRes.length > 0) && (
          <div style={{
            padding: '20px',
            backgroundColor: '#f7f7f7',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            marginTop: '20px',
          }}
          >
            <div style={{
              textAlign: 'left',
              fontWeight: 'bold',
              color: '#333333',
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
              marginBottom: 10,
            }}>
              Previous Results:
            </div>
            <Grid container spacing={2}>
              {prevRes.map((each, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <PrevProgress val={each} day={index + 1} />
                </Grid>
              ))}
            </Grid>
          </div>
        )
      }
    </div>
  );
};

export default Results;
